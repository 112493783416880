import { useMemo } from "react";
import "./list.css";

const List = ({ prop, propPadding, propPadding1 }) => {
  const listStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const divStyle = useMemo(() => {
    return {
      padding: propPadding1,
    };
  }, [propPadding1]);

  return (
    <div className="list4">
      <div className="list5" style={listStyle}>
        <div className="no4">
          <b className="b77">{prop}</b>
        </div>
        <div className="div61">
          <b className="hyejeongnewnopcom2">hyejeong@newnop.com</b>
        </div>
        <div className="div62">
          <div className="div63">홍길동</div>
        </div>
        <div className="div64">
          <b className="b78">1999-01-01</b>
        </div>
        <div className="div65">
          <b className="b79">010-1111-2222</b>
        </div>
        <div className="div66">
          <b className="a-22-22093">
            경기도 용인시 기흥로 58-1, A동 22층 2209호...
          </b>
        </div>
        <div className="category2">
          <button className="button2">
            <div className="wrapper24">
              <b className="b80">보내기 전</b>
            </div>
          </button>
          <div className="div67">
            <button className="wrapper25">
              <b className="b81">전송완료</b>
            </button>
          </div>
        </div>
        <div className="div68" style={divStyle}>
          <b className="b82">상세보기</b>
        </div>
      </div>
    </div>
  );
};

export default List;
