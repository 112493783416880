import "./frame-component3.css";

const FrameComponent3 = () => {
  return (
    <footer className="first-header-parent">
      <button className="first-header">
        <img className="icon52" alt="" src="/--1-1@2x.png" />
      </button>
      <b className="b132">고소의달인</b>
      <div className="c-e-o-registration-info">
        <b className="linguistic2188navercom-container4">
          <p className="p17">
            <span className="span14">대표이사</span>
            <span> 정준모</span>
          </p>
          <p className="p18">
            <span className="span15">사업자등록번호</span>
            <span className="span16">{` `}</span>
            <span>104-86-36406</span>
          </p>
          <p className="linguistic2188navercom4">
            <span className="span17">이메일</span>
            <span className="span18">{` `}</span>
            <span>linguistic2188@naver.com</span>
          </p>
          <p className="p19">
            <span className="span19">주소</span>
            <span className="span20">{` `}</span>
            <span>서울시 마포구 새창로 11, 공덕빌딩 17층 법무법인 다빈치</span>
          </p>
        </b>
        <b className="b133">이용약관</b>
      </div>
    </footer>
  );
};

export default FrameComponent3;
