import { useMemo } from "react";
import "./frame-component4.css";

const FrameComponent4 = ({ prop, placeholder, propMinWidth, propWidth }) => {
  const b1Style = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const inputStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div className="input-id">
      <b className="b131" style={b1Style}>
        {prop}
      </b>
      <div className="div98">
        <input
          className="input11"
          placeholder={placeholder}
          type="text"
          style={inputStyle}
        />
      </div>
    </div>
  );
};

export default FrameComponent4;
