import Component2 from "../components/component2";
import FrameComponent6 from "../components/frame-component6";
import FrameComponent5 from "../components/frame-component5";
import AutoSizing from "../components/auto-sizing";
import "./frame5.css";
import SideNav from "../components/side-nav";

const Frame5 = () => {
  return (
    <div className="div37">
      <img className="tailwind-icon1" alt="" src="/tailwind@2x.png" />
      <div className="child1" />
     
      <main className="side-nav-parent">
      <SideNav/>
        <div className="wrapper8">
          <div className="div39">
            <div className="div40">전송 및 결제하기</div>
          </div>
        </div>
        <section className="frame-parent1">
          <FrameComponent6 />
          <div className="frame-wrapper2">
            <div className="frame-parent2">
              <div className="frame-parent3">
                <div className="frame-parent4">
                  <div className="frame-wrapper3">
                    <div className="frame-child" />
                  </div>
                  <div className="group">
                    <b className="b46">고소인 (고소 또는 소를 제기하는 사람)</b>
                    <b className="b47">
                      당사자 인적사항을 기재하여 주시기 바랍니다.
                    </b>
                  </div>
                </div>
                <form className="frame-form">
                  <div className="frame-parent5">
                    <div className="frame-parent6">
                      <div className="wrapper9">
                        <b className="b48">이름</b>
                      </div>
                      <div className="div41">
                        <input
                          className="input1"
                          placeholder="손혜정"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="frame-parent7">
                      <div className="wrapper10">
                        <b className="b49">주소</b>
                      </div>
                      <div className="frame-parent8">
                        <div className="parent1">
                          <div className="div42">
                            <input
                              className="input2"
                              placeholder="11329"
                              type="text"
                            />
                          </div>
                          <button className="button-pc2">
                            <img
                              className="icon15"
                              alt=""
                              src="/icon15@2x.png"
                            />
                            <b className="data-aggregator">주소 검색</b>
                            <img className="icon16" alt="" src="/icon16.svg" />
                          </button>
                        </div>
                        <div className="div43">
                          <input
                            className="input3"
                            placeholder="경기도 용인시 처인구 경안천로 111"
                            type="text"
                          />
                        </div>
                        <div className="div44">
                          <input
                            className="a-1-101"
                            placeholder="양우내얀애아파트 A동 1층 101호"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <FrameComponent5
                    prop="생년월일"
                    placeholder="1992-06-10"
                    placeholder1="010-7924-5526"
                  />
                </form>
              </div>
              <div className="value-comparator">
                <div className="sequence">
                  <div className="if-else" />
                </div>
                <div className="loop">
                  <b className="b50">피고소인 (고소 또는 소를 당하는 사람)</b>
                  <b className="b51">
                    상대방에 대하여 알고 있는 정보까지만 기재하여 주시기
                    바랍니다.
                  </b>
                </div>
              </div>
              <div className="constant">
                <div className="operator">
                  <form className="assignee">
                    <div className="conne-c-t-o-r">
                      <div className="wrapper11">
                        <b className="b52">이름</b>
                      </div>
                      <div className="div45">
                        <input
                          className="input4"
                          placeholder="강성봉"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="hierarchy-parent">
                      <div className="hierarchy-child">
                        <b className="b53">주소</b>
                      </div>
                      <div className="dependency-tree">
                        <div className="parent2">
                          <div className="div46">
                            <b className="canvas">41414</b>
                          </div>
                          <button className="button-pc3">
                            <img
                              className="icon17"
                              alt=""
                              src="/icon15@2x.png"
                            />
                            <b className="alignment">주소 검색</b>
                            <img className="icon18" alt="" src="/icon16.svg" />
                          </button>
                        </div>
                        <div className="div47">
                          <input
                            className="input5"
                            placeholder="경기도 용인시 기흥구 기흥로 58-1"
                            type="text"
                          />
                        </div>
                        <div className="div48">
                          <input
                            className="a-22-22091"
                            placeholder="A동 22층 2209호"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <FrameComponent5
                    prop="주민등록번호"
                    placeholder="990101-111111"
                    placeholder1="010-3333-4444"
                    propWidth="unset"
                    propMinWidth="64px"
                    propFlex="unset"
                    propMinWidth1="74px"
                    propWidth1="99px"
                  />
                </div>
              </div>
              <div className="effect">
                <div className="blending-mode">
                  <div className="opacity">
                    <div className="visibility" />
                  </div>
                  <div className="parent3">
                    <b className="b54">사건 내용</b>
                    <b className="b55">
                      사건 내용의 대해 알고 있는 정보를 자세하게 기재하여 주시기
                      바랍니다.
                    </b>
                  </div>
                </div>
                <div className="auto-layout">
                  <AutoSizing
                    snapToGrid="1."
                    prop="상대방과의 관계는 어떻게 되나요?"
                    placeholder="상대방과는 2010경 OOO대학교 동창사이로 알고지낸지 10년이 넘은 사이입니다."
                  />
                  <div className="auto-sizing">
                    <div className="frame-parent9">
                      <div className="wrapper12">
                        <b className="b56">2.</b>
                      </div>
                      <b className="b57">사건이 어떻게 발생하였나요?</b>
                    </div>
                    <div className="div49">
                      <b className="text2">
                        2023. 01. 01.에 지인 OOO가 1,000만원을 투자하면 한달
                        안에 2,000만원의 수익금이 발생한다고 속이고 돈을
                        받아갔으나 연락이 두절되었습니다.
                      </b>
                    </div>
                  </div>
                  <AutoSizing
                    snapToGrid="3."
                    prop="위의 사건 내용을 입증할 증거는 무엇이 있나요?"
                    placeholder="돈을 송금한 내역과 상대방과 주고 받은 문자내역이 있습니다."
                    propWidth="297px"
                    propGap="2px"
                    propFlexWrap="unset"
                    propMinWidth="unset"
                  />
                  <AutoSizing
                    snapToGrid="4."
                    prop="경제적, 신체적, 정신적 손실이 발생한 상황은 어떤가요?"
                    placeholder="투자금 1,000만원과 이자를 못받고 있습니다."
                    propWidth="345px"
                    propGap="2px"
                    propFlexWrap="wrap"
                    propMinWidth="214px"
                  />
                </div>
              </div>
              <div className="data-hub">
                <div className="container-parent">
                  <div className="container1">
                    <div className="connection-point">
                      <div className="connection-point-child" />
                    </div>
                    <div className="parent4">
                      <b className="b58">증거자료 첨부</b>
                      <b className="pdf3">
                        증거자료를 첨부해 주세요. PDF, 사진 등 모든 증거자료
                        원본을 전달해 주세요.
                      </b>
                    </div>
                  </div>
                  <div className="frame-wrapper4">
                    <div className="button-pc-parent">
                      <button className="button-pc4">
                        <img className="icon19" alt="" src="/icon6@2x.png" />
                        <b className="label-text1">전체삭제</b>
                        <img className="icon20" alt="" src="/icon7.svg" />
                      </button>
                      <button className="button-pc5">
                        <img className="icon21" alt="" src="/icon15@2x.png" />
                        <b className="label-text2">첨부파일 불러오기</b>
                        <img className="icon22" alt="" src="/icon16.svg" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="frame-parent10">
                  <div className="parent5">
                    <div className="div50">
                      <div className="frame-parent11">
                        <button className="frame-button">
                          <b className="b59">삭제</b>
                        </button>
                        <b className="pdf4">
                          통화 녹음 명품관 정덕성기사님_231103_192836.pdf
                        </b>
                      </div>
                    </div>
                    <div className="div51">
                      <div className="frame-parent12">
                        <button className="wrapper13">
                          <b className="b60">삭제</b>
                        </button>
                        <div className="image-2-wrapper">
                          <img
                            className="image-2-icon"
                            loading="lazy"
                            alt=""
                            src="/image-21@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div52">
                    <div className="frame-parent13">
                      <button className="wrapper14">
                        <b className="b61">삭제</b>
                      </button>
                      <div className="image-2-container">
                        <img
                          className="image-2-icon1"
                          alt=""
                          src="/image-21@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="div53">
                    <div className="frame-parent14">
                      <button className="wrapper15">
                        <b className="b62">삭제</b>
                      </button>
                      <div className="image-2-frame">
                        <img
                          className="image-2-icon2"
                          alt=""
                          src="/image-21@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-parent15">
                <div className="frame-parent16">
                  <div className="frame-parent17">
                    <div className="frame-wrapper5">
                      <div className="frame-item" />
                    </div>
                    <div className="parent6">
                      <b className="b63">이용약관 동의</b>
                      <b className="b64">
                        고소의달인 이용약관 내용을 확인 후 체크해 주세요.
                      </b>
                    </div>
                  </div>
                  <div className="frame-wrapper6">
                    <div className="frame-parent18">
                      <div className="icon-parent1">
                        <div className="icon23">
                          <img
                            className="icon-child"
                            loading="lazy"
                            alt=""
                            src="/vector-20.svg"
                          />
                        </div>
                        <div className="wrapper16">
                          <b className="b65">작성대행 서비스 이용 동의</b>
                        </div>
                        <div className="wrapper17">
                          <b className="b66">자세히보기</b>
                        </div>
                      </div>
                      <div className="icon-parent2">
                        <input className="icon24" type="checkbox" />
                        <div className="wrapper18">
                          <b className="b67">개인정보수집 동의</b>
                        </div>
                        <div className="wrapper19">
                          <b className="b68">자세히보기</b>
                        </div>
                      </div>
                      <div className="icon-parent3">
                        <input className="icon25" type="checkbox" />
                        <div className="wrapper20">
                          <b className="b69">이용약관 동의</b>
                        </div>
                        <div className="wrapper21">
                          <b className="b70">자세히보기</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-mobile1">
                  <img className="icon26" alt="" src="/icon6@2x.png" />
                  <b className="label-text3">전송 및 결제하기</b>
                  <img className="icon27" alt="" src="/icon7.svg" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className="ellipse-parent">
        <div className="ellipse-div" />
        <b className="logic-hub">15</b>
      </div>
    </div>
  );
};

export default Frame5;
