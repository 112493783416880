import "./list1.css";

const List1 = ({ descendantBranch }) => {
  return (
    <div className="list2">
      <div className="list3">
        <div className="no3">
          <b className="descendant-branch">{descendantBranch}</b>
        </div>
        <div className="div54-l">
          <b className="hyejeongnewnopcom1">hyejeong@newnop.com</b>
        </div>
        <div className="div55">
          <b className="b71">홍길동</b>
        </div>
        <div className="div9">
                      <b className="b8">1999-01-01</b>
                    </div>
        {/* <div className="div57">
          <b className="b73">010-1111-2222</b>
        </div> */}
        <div className="div65">
          <b className="b79">010-1111-2222</b>
        </div>
        <div className="div58">
          <b className="a-22-22092">
            경기도 용인시 기흥로 58-1, A동 22층 2209호...
          </b>
        </div>
        <div className="category2">
          <button className="button2">
            <div className="wrapper24">
              <b className="b80">보내기 전</b>
            </div>
          </button>
          <div className="div67">
            <button className="wrapper25">
              <b className="b81">전송완료</b>
            </button>
          </div>
        </div>
        <div className="div60">
          <b className="b76">상세보기</b>
        </div>
      </div>
    </div>
  );
};

export default List1;
