import SideNav from "../components/side-nav";
import ThreeLevelContainer from "../components/three-level-container";
import "./PopupContent.css";
import List1 from "../components/list1";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const PopupContent = () => {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  return (
    <div className="div54">
      <main className="contents">
        <img className="tailwind-icon2" alt="" src="/tailwind@2x.png" />
        <div className="contents-child" />
        {/* <SideNav /> */}
        {/* <List1 descendantBranch="00001" /> */}
        <section className="frame-parent19">
          <div className="frame-wrapper7">
            <div className="parent7">
              <h2 className="h21">고소장/내용증명 동의서 만들기</h2>
              <b className="b71">
                고소의달인의 고소장 및 내용증명 동의서 내용 등록 및 수정
                가능합니다.
              </b>
              <div className="category1">
                <div className="category2">
                  <button className="button11">
                    <b className="b72">고소장</b>
                  </button>
                  <button className="button22">
                    <b className="b73">내용증명</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-parent20">
            <div className="frame-wrapper8">
              <div className="ellipse-group">
                <div className="frame-child1" />
                <b className="b74">2</b>
              </div>
            </div>
            <div className="three-level-container-parent">
              <ThreeLevelContainer prop="고소장 작성 대행 서비스 이용약관 동의서" />
              <ThreeLevelContainer
                prop="고소장 개인정보수집 동의서"
                propHeight="460px"
                propWidth="214px"
                propHeight1="unset"
                propFlex="1"
              />
              <div className="three-level-container">
                <b className="b75">고소장 이용약관 동의서</b>
                {/* <div className="image-121-parent">
                  <img
                    className="image-121-icon"
                    loading="lazy"
                    alt=""
                    src="/image-121@2x.png"
                  />
                  <div className="frame-child2" />
                </div> */}
                <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
      />
                <footer className="button-pc6">
                  <img className="icon28" alt="" src="/icon10@2x.png" />
                  <b className="label-text4">등록하기</b>
                  <img className="icon29" alt="" src="/icon11.svg" />
                </footer>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PopupContent;
