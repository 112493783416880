import { useMemo } from "react";
import "./auto-sizing.css";

const AutoSizing = ({
  snapToGrid,
  prop,
  placeholder,
  propWidth,
  propGap,
  propFlexWrap,
  propMinWidth,
}) => {
  const constraintStyle = useMemo(() => {
    return {
      width: propWidth,
      gap: propGap,
      flexWrap: propFlexWrap,
    };
  }, [propWidth, propGap, propFlexWrap]);

  const b4Style = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  return (
    <div className="auto-sizing1">
      <div className="constraint" style={constraintStyle}>
        <div className="pivot-point">
          <b className="snap-to-grid">{snapToGrid}</b>
        </div>
        <b className="b137" style={b4Style}>
          {prop}
        </b>
      </div>
      <textarea
        className="textarea"
        placeholder={placeholder}
        rows={9}
        cols={54}
      />
    </div>
  );
};

export default AutoSizing;
