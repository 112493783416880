import { useMemo } from "react";
import "./three-level-container.css";

import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const ThreeLevelContainer = ({
  prop,
  propHeight,
  propWidth,
  propHeight1,
  propFlex,
}) => {
  const threeLevelContainerStyle = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  const b6Style = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const personalDataCollectionFormStyle = useMemo(() => {
    return {
      height: propHeight1,
      flex: propFlex,
    };
  }, [propHeight1, propFlex]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <div className="three-level-container1" style={threeLevelContainerStyle}>
      <b className="b146" style={b6Style}>
        {prop}
      </b>
      <div
        className="personal-data-collection-form"
        style={personalDataCollectionFormStyle}
      >
         <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
      />
      </div>
      <div className="button-pc7">
        <img className="icon59" alt="" src="/icon10@2x.png" />
        <b className="label-text5">등록하기</b>
        <img className="icon60" alt="" src="/icon11.svg" />
      </div>
    </div>
  );
};

export default ThreeLevelContainer;
