import { useMemo } from "react";
import "./component.css";

const Component = ({
  icon,
  prop,
  propFlex,
  propGap,
  propMinWidth,
  propLineHeight,
}) => {
  const frameDivStyle = useMemo(() => {
    return {
      flex: propFlex,
      gap: propGap,
    };
  }, [propFlex, propGap]);

  const bStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
      lineHeight: propLineHeight,
    };
  }, [propMinWidth, propLineHeight]);

  return (
    <div className="div69">
      <div className="icon-parent4" style={frameDivStyle}>
        <img className="icon28" alt="" src={icon} />
        <div className="logic-branch">
          <b className="b83" style={bStyle}>
            {prop}
          </b>
        </div>
      </div>
      <div className="child3" />
      <img className="expand-more-icon2" alt="" src="/expand-more.svg" />
    </div>
  );
};

export default Component;
