// import Component3 from "./Component3";
// import "./side-nav.css";
import Frame from "../pages/frame";
import Component from "./component";
import { useNavigate } from "react-router-dom";

const SideNav = () => {

    const navigate = useNavigate();
    const handleNavigate1 = ()=>{
        navigate('/list')
    }


    const handleNavigate2 = ()=>{
        navigate('/content')
    }


    const handleNavigate3 = ()=>{
        navigate('/content')
    }

  return (

    <>
    <div className="side-nav">
    <div className="logo-wrapper">
      <img
        className="logo-icon"
        loading="lazy"
        alt=""
        src="/logo@2x.png"
      />
    </div>
    <div className="gnb-wrap-fixed">
      <div className="gnb-wrap">
        <div className="div14">
          <div className="item" />
          <div className="input-filter">
            <div className="output-merge"  onClick={handleNavigate1}>
              <img className="icon" alt="" src="/icon.svg" />
              <div className="function-caller">
                <b className="b18">고소장 리스트</b>
              </div>
            </div>
          </div>
          <div className="inner" />
          <img
            className="expand-more-icon"
            alt=""
            src="/expand-more.svg"
          />
        </div>
        <div className="div14" onClick={handleNavigate2}>
          <div className="item" />
          <div className="input-filter">
            <div className="output-merge" >
              <img className="icon" alt="" src="/icon.svg" />
              <div className="function-caller">
                <b className="b18">내용증명 리스트</b>
              </div>
            </div>
          </div>
          <div className="inner" />
          <img
            className="expand-more-icon"
            alt=""
            src="/expand-more.svg"
          />
        </div>


        <div className="div14" onClick={handleNavigate3}>
          <div className="item" />
          <div className="input-filter">
            <div className="output-merge" >
              <img className="icon" alt="" src="/icon.svg" />
              <div className="function-caller">
                <b className="b18">고소장/내용증명 동의서 만들기</b>
              </div>
            </div>
          </div>
          <div className="inner" />
          <img
            className="expand-more-icon"
            alt=""
            src="/expand-more.svg"
          />
        </div>
        {/* <Component icon="/icon-1.svg" prop="내용증명 리스트"  onClick={handleNavigate2}/> */}
        {/* <Component
          icon="/icon-2.svg"
          prop="고소장/내용증명 동의서 만들기"
          propFlex="1"
          propGap="3px"
          propMinWidth="unset"
          propLineHeight="140%"
        /> */}
        <Component
          icon="/icon-3.svg"
          prop="고소장/내용증명 가격설정"
          propFlex="unset"
          propGap="4px"
          propMinWidth="unset"
          propLineHeight="20px"
        />
        <Component
          icon="/icon-4.svg"
          prop="결제내역 보기"
          propFlex="unset"
          propGap="4px"
          propMinWidth="83px"
          propLineHeight="20px"
        />
      </div>
    </div>
  </div>

  </>
  );
};

export default SideNav;
