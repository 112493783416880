import List1 from "../components/list1";
import List from "../components/list";
import Component from "../components/component";
import "./frame.css";
import SideNav from "../components/side-nav";

const Frame = () => {
  return (
    <div className="div">
      <img className="tailwind-icon" alt="" src="/tailwind@2x.png" />
      <div className="child" />
      <main className="tree-trunk">
        <section className="leaf-cluster-wrapper">
          <header className="leaf-cluster">
            <div className="sibling-set">
              <h2 className="h2">고소장 리스트</h2>
              <h3 className="h3">고소의달인 고소장 리스트입니다.</h3>
            </div>
            <div className="table-list">
              <div className="table">
                <div className="title">
                  <div className="no">
                    <b className="no1">No.</b>
                  </div>
                  <div className="div1">
                    <b className="b">전달 받은 이메일 주소</b>
                  </div>
                  <div className="div2">
                    <b className="b1">고소인 이름</b>
                  </div>
                  <div className="div3">
                    <b className="b2">고소인 생년월일</b>
                  </div>
                  <div className="div4">
                    <b className="b3">고소인 연락처</b>
                  </div>
                  <div className="div5">
                    <b className="b4">고소인 주소</b>
                  </div>
                  <div className="div6">
                    <b className="b5">전송 상태 확인 및 변경</b>
                    <img
                      className="bxsup-arrow-icon"
                      alt=""
                      src="/bxsuparrow@2x.png"
                    />
                  </div>
                </div>
                <List1 descendantBranch="00001" />
                <div className="list">
                  <div className="list1">
                    <div className="no2">
                      <b className="b6">00002</b>
                    </div>
                    <div className="div7">
                      <b className="hyejeongnewnopcom">hyejeong@newnop.com</b>
                    </div>
                    <div className="div8">
                      <b className="b7">홍길동</b>
                    </div>
                    <div className="div9">
                      <b className="b8">1999-01-01</b>
                    </div>
                    <div className="div10">
                      <b className="b9">010-1111-2222</b>
                    </div>
                    <div className="div11">
                      <b className="a-22-2209">
                        경기도 용인시 기흥로 58-1, A동 22층 2209호...
                      </b>
                    </div>
                    <div className="category">
                      <div className="div12">
                        <button className="wrapper">
                          <b className="b10">보내기 전</b>
                        </button>
                      </div>
                      <button className="button">
                        <div className="container">
                          <b className="b11">전송완료</b>
                        </div>
                      </button>
                    </div>
                    <div className="div13">
                      <b className="b12">상세보기</b>
                    </div>
                  </div>
                </div>
                <List1 descendantBranch="00003" />
                <List prop="00004" />
                <List
                  prop="00005"
                  propPadding="var(--padding-base) 0px"
                  propPadding1="var(--padding-2xs) var(--padding-9xl-5)"
                />
                <List
                  prop="00006"
                  propPadding="var(--padding-base) 0px"
                  propPadding1="var(--padding-2xs) var(--padding-9xl-5)"
                />
                <List
                  prop="00007"
                  propPadding="var(--padding-base) 0px"
                  propPadding1="var(--padding-2xs) var(--padding-9xl-5)"
                />
                <List
                  prop="00008"
                  propPadding="var(--padding-base) 0px"
                  propPadding1="var(--padding-2xs) var(--padding-9xl-5)"
                />
                <List
                  prop="00009"
                  propPadding="var(--padding-base) 0px"
                  propPadding1="var(--padding-2xs) var(--padding-9xl-5)"
                />
                <List
                  prop="00010"
                  propPadding="var(--padding-base) 0px"
                  propPadding1="var(--padding-2xs) var(--padding-9xl-5)"
                />
              </div>
            </div>
            <div className="pagination">
              <div className="first-page-parent">
                <img
                  className="first-page-icon"
                  loading="lazy"
                  alt=""
                  src="/first-page.svg"
                />
                <img
                  className="chevron-left-icon"
                  loading="lazy"
                  alt=""
                  src="/chevron-left.svg"
                />
              </div>
              <nav className="deselect-all">
                <b className="b13">1</b>
                <b className="b14">2</b>
                <b className="b15">3</b>
                <b className="b16">4</b>
                <b className="b17">5</b>
              </nav>
              <div className="chevron-right-parent">
                <img
                  className="chevron-right-icon"
                  loading="lazy"
                  alt=""
                  src="/chevron-right.svg"
                />
                <img
                  className="last-page-icon"
                  loading="lazy"
                  alt=""
                  src="/last-page.svg"
                />
              </div>
            </div>
          </header>
        </section>
 <SideNav/>
      </main>
    </div>
  );
};

export default Frame;
