import FrameComponent4 from "../components/frame-component4";
import FrameComponent3 from "../components/frame-component3";
import "./frame4.css";
import { useNavigate } from "react-router-dom";
const Frame4 = () => {
const navigate = useNavigate ();
  const handleNavigateAll = ()=>{
    navigate('/list')
  }
  return (
    <div className="div35">
      <main className="main3">
        <section className="frame-section">
          <div className="parent">
            <b className="b44">
              <span>{`당신을 위한 `}</span>
              <span className="span4">형사 고소 도우미</span>
            </b>
            <img className="icon11" loading="lazy" alt="" src="/--1@2x.png" />
          </div>
          <div className="div36">
            <form className="form">
              <h1 className="h1">로그인</h1>
              <div className="input-id-parent">
                <FrameComponent4
                  prop="아이디"
                  placeholder="아이디를 입력해 주세요"
                />
                <FrameComponent4
                  prop="비밀번호"
                  placeholder="비밀번호를 입력해 주세요"
                  propMinWidth="56px"
                  propWidth="142px"
                />
              </div>
              <button className="button-mobile"  onClick={handleNavigateAll}>
                <img className="icon12" alt="" src="/icon13@2x.png" />
                <b className="logo-and-title">로그인</b>
                <img className="icon13" alt="" src="/icon14.svg" />
              </button>
            </form>
          </div>
        </section>
      </main>
      <FrameComponent3 />
    </div>
  );
};

export default Frame4;
