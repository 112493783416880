// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b131,
.input11 {
  display: inline-block;
  position: relative;
}
.b131 {
  line-height: 140%;
  min-width: 42px;
}
.input11 {
  width: 129px;
  border: 0;
  outline: 0;
  font-family: var(--web-button1-bold);
  font-size: var(--mobile-button1-bold-size);
  background-color: transparent;
  height: 20px;
  line-height: 150%;
  font-weight: 700;
 color: #EB6E67;
  /* text-align: center; */
  padding: 0;
}

.input11::placeholder{
  color: var(--grayscale-400);
}
.div98,
.input-id {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.div98 {
  border-radius: var(--br-9xs);
  background-color: var(--grayscale-0);
  border: 1px solid var(--grayscale-300);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-5xs);
}
.input-id {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--web-label1-bold-size);
  color: var(--grayscale-900);
  font-family: var(--web-button1-bold);
}
`, "",{"version":3,"sources":["webpack://./src/components/frame-component4.css"],"names":[],"mappings":"AAAA;;EAEE,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,YAAY;EACZ,SAAS;EACT,UAAU;EACV,oCAAoC;EACpC,0CAA0C;EAC1C,6BAA6B;EAC7B,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;CACjB,cAAc;EACb,wBAAwB;EACxB,UAAU;AACZ;;AAEA;EACE,2BAA2B;AAC7B;AACA;;EAEE,mBAAmB;EACnB,aAAa;EACb,2BAA2B;AAC7B;AACA;EACE,4BAA4B;EAC5B,oCAAoC;EACpC,sCAAsC;EACtC,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,sCAAsC;EACtC,2BAA2B;EAC3B,oCAAoC;AACtC","sourcesContent":[".b131,\n.input11 {\n  display: inline-block;\n  position: relative;\n}\n.b131 {\n  line-height: 140%;\n  min-width: 42px;\n}\n.input11 {\n  width: 129px;\n  border: 0;\n  outline: 0;\n  font-family: var(--web-button1-bold);\n  font-size: var(--mobile-button1-bold-size);\n  background-color: transparent;\n  height: 20px;\n  line-height: 150%;\n  font-weight: 700;\n color: #EB6E67;\n  /* text-align: center; */\n  padding: 0;\n}\n\n.input11::placeholder{\n  color: var(--grayscale-400);\n}\n.div98,\n.input-id {\n  align-self: stretch;\n  display: flex;\n  justify-content: flex-start;\n}\n.div98 {\n  border-radius: var(--br-9xs);\n  background-color: var(--grayscale-0);\n  border: 1px solid var(--grayscale-300);\n  overflow: hidden;\n  flex-direction: row;\n  align-items: center;\n  padding: var(--padding-5xs);\n}\n.input-id {\n  flex-direction: column;\n  align-items: flex-start;\n  gap: var(--gap-5xs);\n  text-align: left;\n  font-size: var(--web-label1-bold-size);\n  color: var(--grayscale-900);\n  font-family: var(--web-button1-bold);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
