import { useMemo } from "react";
import "./component2.css";

const Component2 = ({
  icon,
  prop,
  propFlex,
  propGap,
  propMinWidth,
  propLineHeight,
}) => {
  const frameDiv3Style = useMemo(() => {
    return {
      flex: propFlex,
      gap: propGap,
    };
  }, [propFlex, propGap]);

  const b2Style = useMemo(() => {
    return {
      minWidth: propMinWidth,
      lineHeight: propLineHeight,
    };
  }, [propMinWidth, propLineHeight]);

  return (
    <div className="div99">
      <div className="icon-parent5" style={frameDiv3Style}>
        <img className="icon53" alt="" src={icon} />
        <div className="wrapper30">
          <b className="b134" style={b2Style}>
            {prop}
          </b>
        </div>
      </div>
      <div className="child4" />
      <img className="expand-more-icon3" alt="" src="/expand-more.svg" />
    </div>
  );
};

export default Component2;
