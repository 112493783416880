// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b134,
.icon53 {
  position: relative;
}
.icon53 {
  height: 52px;
  width: 52px;
  border-radius: var(--br-9xs);
  overflow: hidden;
  flex-shrink: 0;
}
.b134 {
  line-height: 140%;
  display: inline-block;
  min-width: 97px;
}
.icon-parent5,
.wrapper30 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.wrapper30 {
  flex-direction: column;
  padding: var(--padding-base) 0 0;
}
.icon-parent5 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-10xs);
}
.child4,
.expand-more-icon3 {
  position: relative;
  display: none;
}
.child4 {
  height: 64px;
  width: 4px;
  background-color: var(--color-darkgray-100);
}
.expand-more-icon3 {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.div99 {
  align-self: stretch;
  flex: 0.8125 1;
  background-color: var(--grayscale-0);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-7xs) var(--padding-xl);
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--web-label1-bold-size);
  color: var(--color-black);
  font-family: var(--web-button1-bold);
}
`, "",{"version":3,"sources":["webpack://./src/components/component2.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,WAAW;EACX,4BAA4B;EAC5B,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,eAAe;AACjB;AACA;;EAEE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;AAC7B;AACA;EACE,sBAAsB;EACtB,gCAAgC;AAClC;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;AACtB;AACA;;EAEE,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,YAAY;EACZ,UAAU;EACV,2CAA2C;AAC7C;AACA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,cAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;EAC3B,6CAA6C;EAC7C,oBAAoB;EACpB,gBAAgB;EAChB,sCAAsC;EACtC,yBAAyB;EACzB,oCAAoC;AACtC","sourcesContent":[".b134,\n.icon53 {\n  position: relative;\n}\n.icon53 {\n  height: 52px;\n  width: 52px;\n  border-radius: var(--br-9xs);\n  overflow: hidden;\n  flex-shrink: 0;\n}\n.b134 {\n  line-height: 140%;\n  display: inline-block;\n  min-width: 97px;\n}\n.icon-parent5,\n.wrapper30 {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n.wrapper30 {\n  flex-direction: column;\n  padding: var(--padding-base) 0 0;\n}\n.icon-parent5 {\n  align-self: stretch;\n  flex-direction: row;\n  gap: var(--gap-10xs);\n}\n.child4,\n.expand-more-icon3 {\n  position: relative;\n  display: none;\n}\n.child4 {\n  height: 64px;\n  width: 4px;\n  background-color: var(--color-darkgray-100);\n}\n.expand-more-icon3 {\n  height: 24px;\n  width: 24px;\n  overflow: hidden;\n  flex-shrink: 0;\n}\n.div99 {\n  align-self: stretch;\n  flex: 0.8125;\n  background-color: var(--grayscale-0);\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  justify-content: flex-start;\n  padding: var(--padding-7xs) var(--padding-xl);\n  gap: var(--gap-base);\n  text-align: left;\n  font-size: var(--web-label1-bold-size);\n  color: var(--color-black);\n  font-family: var(--web-button1-bold);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
