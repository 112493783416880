import "./frame-component6.css";

const FrameComponent6 = () => {
  return (
    <div className="frame-parent29">
      <div className="frame-parent30">
        <div className="frame-wrapper8">
          <div className="parent16">
            <h2 className="h21">고소장 리스트 상세보기</h2>
            <h3 className="h36">고소의달인 고소장 리스트의 상세보기 입니다.</h3>
          </div>
        </div>
        <div className="div100">이메일 주소</div>
      </div>
      <div className="div101">
        <input
          className="hyejeongnewnopcom3"
          placeholder="hyejeong@newnop.com"
          type="text"
        />
      </div>
    </div>
  );
};

export default FrameComponent6;
