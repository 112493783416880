import { useMemo } from "react";
import "./frame-component5.css";

const FrameComponent5 = ({
  prop,
  placeholder,
  placeholder1,
  propWidth,
  propMinWidth,
  propFlex,
  propMinWidth1,
  propWidth1,
}) => {
  const outputGeneratorStyle = useMemo(() => {
    return {
      width: propWidth,
      minWidth: propMinWidth,
    };
  }, [propWidth, propMinWidth]);

  const b3Style = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth1,
    };
  }, [propFlex, propMinWidth1]);

  const input1Style = useMemo(() => {
    return {
      width: propWidth1,
    };
  }, [propWidth1]);

  return (
    <div className="input-processor-parent">
      <div className="input-processor">
        <div className="output-generator" style={outputGeneratorStyle}>
          <b className="b135" style={b3Style}>
            {prop}
          </b>
        </div>
        <div className="div102">
          <input
            className="input12"
            placeholder={placeholder}
            type="text"
            style={input1Style}
          />
        </div>
      </div>
      <div className="input-processor1">
        <div className="wrapper31">
          <b className="b136">연락처</b>
        </div>
        <div className="div103">
          <input className="input13" placeholder={placeholder1} type="text" />
        </div>
      </div>
    </div>
  );
};

export default FrameComponent5;
